import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Colours } from "../helpers/Colours";
import classNames from "classnames";
import { useSelector } from "react-redux";

import Carousel from "react-material-ui-carousel";
import { Paper } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";

import Stars from "./Stars";

const useStyles = makeStyles((theme) => ({
	container: {
		width: "100vw",
		height: "100%",
		paddingTop: "6.9vh",
		transition: "all 0.5s ease",
		"& .MuiContainer-root": {
			padding: "0px !important",
			margin: "0px !important",
		},
	},
	activeGameInfo: {
		width: "65% !important",
		transition: "all 0.5s ease",
	},
	image: {
		zIndex: 0,
		width: "100%",
		height: "100%",
		objectFit: "cover",

		"&:after": {
			backgroundColor: "rgba(0, 0, 0, 0.54)",
		},
	},
	mastheadContainer: {
		backgroundColor: "black",
		transition: "all 0.5s ease",
		width: "100%",
		height: "75vh",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	stars: {
		display: "flex",
		alignItems: "center",
	},
	platforms: {
		paddingTop: 10,
		color: Colours.white,
	},
	InformationSection: {
		maxWidth: 400,
		position: "absolute",
		display: "flex",
		flexDirection: "column",
		left: 100,
		top: "60%",
		paddingBottom: 30,
		zIndex: 998,
		"& .MuiTypography-h2": {
			paddingTop: 20,
			fontSize: "270%",
		},
		"& .MuiTypography-h5": {
			color: Colours.primary,
			fontSize: "100%",
			margin: 0,
			marginTop: 5,
		},
	},
	descriptionBox: {},
}));

export default function CarouselComp({ games }) {
	const classes = useStyles();

	return (
		<Carousel
			animation="fade"
			autoPlay={true}
			interval={5000}
			timeout={500}
			// navButtonsAlwaysVisible={true}
			className={classes.container}
		>
			{games.map((item, i) => (
				<Item key={i} game={item} />
			))}
		</Carousel>
	);
}

function Item({ game }) {
	const classes = useStyles();
	const { drawerStatus } = useSelector((state) => state.status);

	const filterTags = (arr) => {
		let list = arr.filter((el) => el !== undefined).slice(0, 10);
		return list.join(", ");
	};

	return (
		<Paper>
			<Container
				className={classNames(
					classes.mastheadContainer,
					drawerStatus ? classes.activeGameInfo : null
				)}
				component="main"
				maxWidth="xs"
			>
				<CssBaseline />
				<img
					src={game.background_image}
					alt={game.name}
					className={classes.image}
				></img>
				<div className={classes.InformationSection}>
					<Typography component="h1" variant="h2">
						{game.name}
					</Typography>
					<div className={classes.stars}>
						<Stars rating={game.metacritic} numRatings={game.ratings_count} />
					</div>
					<div className={classes.platforms}>
						{game.platforms
							.map((el) => {
								return el.platform.name;
							})
							.join(", ")}
					</div>
					<div className={classes.platforms}>
						{filterTags(
							game.tags.map((el) => {
								let name;
								if (el.language === "eng") {
									// return el.name;
									name = el.name;
								}
								return name;
							})
						)}
					</div>
				</div>
			</Container>
		</Paper>
	);
}
