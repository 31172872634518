import axios from "axios";
import GlobalController from "../GlobalController";
import {
	FETCH_GAMES,
	FETCH_SEARCHED,
	FETCH_FAVOURITES,
} from "../actions/types";
import config from "../config";

//Action Creator

function filterGames(games, rating) {
	let newList = games.filter(
		(el) =>
			el.short_screenshots !== null &&
			el.short_screenshots.length > 0 &&
			el.ratings_count > rating
	);
	newList.sort(function (a, b) {
		return b.ratings_count - a.ratings_count;
	});
	return newList;
}

export const loadGames = (genre) => async (dispatch) => {
	try {
		const popularData = await axios.get(
			GlobalController.popularGamesURL(genre)
		);
		const newGamesData = await axios.get(GlobalController.newGamesURL(genre));
		const upcomingData = await axios.get(
			GlobalController.upcomingGamesURL(genre)
		);

		dispatch({
			type: FETCH_GAMES,
			payload: {
				popular: filterGames(popularData.data.results, 50),
				newGames: filterGames(newGamesData.data.results, -1),
				upcoming: filterGames(upcomingData.data.results, 0),
			},
		});
	} catch (err) {
		console.log(err);
	}
};

export const searchGames = (game_name) => async (dispatch) => {
	const gameName = game_name.toLowerCase();

	const searchedGame = await axios.get(
		GlobalController.searchGameURL(gameName)
	);

	function filterGames(games, rating) {
		let newList = games.filter(
			(el) =>
				el.short_screenshots !== null &&
				el.short_screenshots.length > 0 &&
				el.ratings_count > rating
		);

		// console.log(gameName.split(" ")[0]);
		let l2 = newList.filter((el) => el.name.toLowerCase().includes(gameName));

		l2.sort(function (a, b) {
			return b.ratings_count - a.ratings_count;
		});
		return l2;
	}

	dispatch({
		type: FETCH_SEARCHED,
		payload: filterGames(searchedGame.data.results, 25),
	});
};

export const loadFavourites = (userId) => async (dispatch) => {
	const database = config.database();
	let dataList = [];
	let gameIds = [];

	fetchFavouritesFromDatabase();

	async function fetchFavouritesFromDatabase() {
		let userGames = await database.ref(`users/${userId}`);

		userGames.on("value", (snapshot) => {
			const data = snapshot.val();
			if (data !== null) {
				for (const [key, value] of Object.entries(data)) {
					// console.log(`${key} : ${value.gameId}`);
					gameIds.push(value.gameId);
				}
			}
			returnFavouriteDetails();
		});
	}

	function returnFavouriteDetails() {
		gameIds.map(async (el) => {
			const data = await axios.get(GlobalController.gameDetailsURL(el));
			dataList.push(data);
		});

		const TempList = dataList;
		return TempList;
	}

	dispatch({
		type: FETCH_FAVOURITES,
		payload: {
			games: dataList,
			ids: gameIds,
		},
	});
};
