import axios from "axios";
import GlobalController from "../GlobalController";

import { LOADING_DETAIL, GET_DETAIL } from "../actions/types";

export const loadDetail = (id) => async (dispatch) => {
	dispatch({
		type: LOADING_DETAIL,
	});

	const detailData = await axios.get(GlobalController.gameDetailsURL(id));

	const screenShotData = await axios.get(
		GlobalController.gameScreenshotURL(id)
	);

	const checkedClip = detailData.data.clip ? detailData.data.clip.clip : "";

	dispatch({
		type: GET_DETAIL,
		payload: {
			game: detailData.data,
			screen: screenShotData.data,
			clip: checkedClip,
		},
	});
};
