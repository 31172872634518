export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";

export const GET_DETAIL = "GET_DETAIL";
export const LOADING_DETAIL = "LOADING_DETAIL";
export const CHANGE_GENRE = "CHANGE_GENRE";

export const FETCH_GAMES = "FETCH_GAMES";
export const FETCH_FAVOURITES = "FETCH_FAVOURITES";
export const FETCH_SEARCHED = "FETCH_SEARCHED";
export const CLEAR_SEARCHED = "CLEAR_SEARCHED";

export const SET_VIDEO_PLAYING_STATUS = "VIDEO_PLAYING_STATUS";
export const SET_DRAWER_STATUS = "DRAWER_STATUS";
