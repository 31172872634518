import { SIGN_IN, SIGN_OUT } from "./types";

export const signIn = (userId, userName, userImage, userEmail) => {
  return {
    type: SIGN_IN,
    payload: {
      id: userId,
      name: userName,
      image: userImage,
      email: userEmail,
    },
  };
};

export const signOut = () => {
  return {
    type: SIGN_OUT,
  };
};
