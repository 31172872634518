import React from "react";
import image from "../images/link.jpg";
import { Colours } from "../helpers/Colours";
import Logo from "../images/Retrobase.png";

// Material
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

// Components
import GoogleAuth from "../components/GoogleAuth";

const useStyles = makeStyles((theme) => ({
	container: {
		backgroundImage: "url(" + image + ")",
		flex: 1,
		backgroundPosition: "center",
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		backgroundColor: "black",
		height: "100vh",
	},
	innerContainer: {
		backgroundColor: "rgba(0,0,0, 0.80)",
		height: "100%",
		width: "100%",
		display: "flex",
		alignItems: "center",
		"& .MuiContainer-maxWidthXs": {
			maxWidth: 1300,
		},
	},
	image: {
		position: "absolute",
		top: 30,
		left: 30,
		width: 200,
		[theme.breakpoints.down("xs")]: {
			width: 150,
		},
	},
	paper: {
		padding: "40px 70px",
		paddingBottom: 120,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		[theme.breakpoints.down("xs")]: {
			padding: 20,
		},
		"& .MuiTypography-h2": {
			textAlign: "center",
			color: Colours.primary,
			fontWeight: "bold",
			fontSize: "600%",
			marginBottom: 25,
			[theme.breakpoints.down("xs")]: {
				fontSize: "200%",
			},
		},

		"& .MuiTypography-h4": {
			color: Colours.primary,
			textAlign: "center",
			fontSize: "280%",
			marginBottom: 15,
			[theme.breakpoints.down("xs")]: {
				fontSize: "140%",
				fontWeight: "bold",
				marginBottom: 5,
			},
		},
		"& .MuiTypography-h5": {
			color: Colours.primary,
			textAlign: "center",
			fontSize: "180%",
			[theme.breakpoints.down("xs")]: {
				fontSize: "130%",
				fontWeight: "bold",
			},
		},
	},
	form: {
		width: "65%",
		marginTop: theme.spacing(3),
		display: "flex",
		flexDirection: "row",
		"& .MuiOutlinedInput-input": {
			padding: 0,
			height: 60,
		},
		"& .MuiFormControl-marginNormal": {
			margin: 0,
		},
	},
	formText: {
		display: "flex",
		borderRadius: 0,
		"& .MuiInputBase-input": {
			backgroundColor: Colours.primary,
			color: Colours.black,
		},
		"& .MuiFormLabel-root": {},
	},
	submit: {
		borderRadius: 0,
		fontSize: 22,
		color: Colours.primary,
		backgroundColor: Colours.blue,
		width: 200,
		marginTop: 25,
		[theme.breakpoints.down("xs")]: {
			marginTop: 40,
			fontSize: 16,
			maxWidth: 150,
			height: 40,
		},
	},
}));

export default function Dashboard(props) {
	const classes = useStyles();

	function buildContainer() {
		return (
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				<div className={classes.paper}>
					<Typography
						component="h1"
						variant="h2"
						className={classes.headerText}
					>
						Top rated games,
						<br />
						any genre.
					</Typography>
					<Typography
						component="h1"
						variant="h4"
						className={classes.headerText}
					>
						Tailored and filtered to fit your taste.
					</Typography>
					<Typography
						component="h1"
						variant="h5"
						className={classes.headerText}
					>
						Love gaming? Sign up to get started!
					</Typography>
					<GoogleAuth loginPage={true} />
				</div>
			</Container>
		);
	}
	return (
		<div className={classes.container}>
			<div className={classes.innerContainer}>
				<img alt="RetroBase Logo" src={Logo} className={classes.image}></img>
				{/* <SignInButton /> */}
				{buildContainer()}
			</div>
		</div>
	);
}
