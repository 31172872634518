import React from "react";
import PropTypes from "prop-types";
import { Colours } from "../helpers/Colours";

// State
import { useSelector } from "react-redux";

// Material
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import DescriptionIcon from "@material-ui/icons/Description";
import InfoIcon from "@material-ui/icons/Info";

// Components
import Stars from "./Stars";
import GalleryList from "./GalleryList";

//#region Tab Functionality
function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`nav-tabpanel-${index}`}
			aria-labelledby={`nav-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `nav-tab-${index}`,
		"aria-controls": `nav-tabpanel-${index}`,
	};
}

function LinkTab(props) {
	return (
		<Tab
			component="a"
			onClick={(event) => {
				event.preventDefault();
			}}
			{...props}
		/>
	);
}

//#endregion

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		zIndex: 998,
		"& .MuiPaper-root": {
			backgroundColor: Colours.red,
		},
		[theme.breakpoints.down("xs")]: {
			"& .MuiTypography-h2": {
				fontSize: "150%",
			},
		},
		"& .MuiTypography-h5": {
			[theme.breakpoints.down("xs")]: {
				fontSize: "110%",
			},
		},
	},
	tabs: {
		backgroundColor: Colours.background,
		color: Colours.primary,
		"& .MuiTab-wrapper": {
			color: Colours.bodyText,
		},
	},
	pageOne: {
		padding: 10,
		[theme.breakpoints.down("xs")]: {
			padding: 0,
		},
	},
	pageTwo: {},
	pageThree: {},
	players: {
		marginTop: 10,
		display: "flex",
		flexDirection: "row",
	},
	detail: {
		display: "flex",
		flexDirection: "row",
		marginTop: 10,
	},
	detailTitle: {
		marginRight: 10,
		"& .MuiTypography-h6": {
			fontSize: "120%",
			color: Colours.grey,
			[theme.breakpoints.down("xs")]: {
				fontSize: "100%",
			},
		},
	},
	detailText: {
		"& .MuiTypography-h6": {
			color: Colours.bodyText,
			fontSize: "120%",
			[theme.breakpoints.down("xs")]: {
				fontSize: "100%",
			},
		},
	},
}));

export default function DrawerTabs() {
	const classes = useStyles();
	const [value, setValue] = React.useState(0);
	const { game, screen, isLoading } = useSelector((state) => state.detail);
	const smallWidth = window.innerWidth < 600;

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	function calculateRatings(ratings) {
		let totalRatings = 0;
		ratings.map((rating) => {
			return (totalRatings += rating.count);
		});
		return totalRatings;
	}

	function parseReleaseDate(date) {
		if (date !== null) {
			const dates = date.split("-");
			return `${dates[2]}-${dates[1]}-${dates[0]}`;
		} else {
			return "Unknown";
		}
	}

	function buildPageOne() {
		return (
			<>
				{!isLoading && (
					<div className={classes.pageOne}>
						<Typography component="h1" variant="h2">
							{game.name ?? ""}
						</Typography>
						<Typography
							style={{ fontWeight: "lighter", color: "grey" }}
							component="h1"
							variant="h3"
						>
							{game.developers.length > 0 ? game.developers[0].name : "Unknown"}
						</Typography>
						<Stars
							rating={game.metacritic}
							numRatings={calculateRatings(game.ratings)}
						/>
						<div
							style={{ color: Colours.white, paddingTop: 10 }}
							dangerouslySetInnerHTML={{ __html: game.description }}
						></div>
					</div>
				)}
			</>
		);
	}

	function buildDetailsDescription(bool, title, description) {
		if (!bool || description === null) return;
		return (
			<div className={classes.detail}>
				<div className={classes.detailTitle}>
					<Typography component="h1" variant="h6">
						{title}
					</Typography>
				</div>
				<div className={classes.detailText}>
					<Typography component="h1" variant="h6">
						{description}
					</Typography>
				</div>
			</div>
		);
	}

	function buildPageTwo() {
		return (
			<div className={classes.pageTwo}>
				<Typography component="h1" variant="h3" style={{ fontSize: 30 }}>
					{game.developers.length > 0 ? game.developers[0].name : ""}
				</Typography>
				{buildDetailsDescription(
					game.genres.length > 0,
					"Genre:",
					game.genres
						.map((el) => {
							return el.name;
						})
						.join(", ")
				)}
				{buildDetailsDescription(
					game.platforms.length > 0,
					"Platform:",
					game.platforms
						.map((el) => {
							return el.platform.name;
						})
						.join(", ")
				)}
				{buildDetailsDescription(
					game.platforms,
					"Release Date:",
					parseReleaseDate(game.released)
				)}
				{buildDetailsDescription(
					game.website !== "",
					"Website:",
					<a href={game.website} target="_blank" rel="noreferrer">
						{game.website}
					</a>
				)}
				{buildDetailsDescription(
					game.reddit_url !== "",
					"Reddit:",
					<a href={game.reddit_url} target="_blank" rel="noreferrer">
						{game.reddit_url}
					</a>
				)}
				<Typography
					style={{ marginTop: 20, marginBottom: 20 }}
					component="h1"
					variant="h2"
				>
					Game Stats
				</Typography>
				{game.added_by_status !== null &&
					buildDetailsDescription(
						true,
						"Players Owned:",
						game.added_by_status.owned
					)}
				{game.added_by_status !== null &&
					buildDetailsDescription(
						true,
						"Players Beaten:",
						game.added_by_status.beaten
					)}
			</div>
		);
	}

	function buildPageThree() {
		return (
			<div className={classes.pageThree}>
				<GalleryList images={screen.results} />
			</div>
		);
	}

	return (
		<div className={classes.root}>
			<AppBar position="static">
				<Tabs
					variant="fullWidth"
					value={value}
					onChange={handleChange}
					aria-label="nav tabs example"
					indicatorColor="primary"
					textColor="primary"
					className={classes.tabs}
					style={{ color: Colours.primary }}
				>
					<LinkTab
						label={smallWidth ? <DescriptionIcon /> : "Description"}
						{...a11yProps(0)}
					/>
					<LinkTab
						label={smallWidth ? <InfoIcon /> : "Details"}
						{...a11yProps(1)}
					/>
					<LinkTab
						label={smallWidth ? <PhotoLibraryIcon /> : "Gallery"}
						{...a11yProps(2)}
					/>
				</Tabs>
			</AppBar>
			<TabPanel value={value} index={0}>
				{game.name && buildPageOne()}
			</TabPanel>
			<TabPanel value={value} index={1}>
				{game.name && buildPageTwo()}
			</TabPanel>
			<TabPanel value={value} index={2}>
				{game.name && buildPageThree()}
			</TabPanel>
		</div>
	);
}
