import React, { useState } from "react";
import classNames from "classnames";
import { Colours } from "../helpers/Colours";
import { makeStyles } from "@material-ui/core";

// State and Reducers
import { useSelector, useDispatch } from "react-redux";
import { loadDetail } from "../actions/detailAction";
import {
	setDrawerStatus,
	setVideoPlayingStatus,
} from "../actions/statusAction";
// import config from "../config";

//Material
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// Components
import NavBar from "../components/NavBar";
import GameInfo from "../components/GameInfo";

const useStyles = makeStyles((theme) => ({
	container: {
		backgroundColor: Colours.darkBackground,
		minHeight: "100vh",
		width: "100vw",
		transition: "all 0.5s ease",
	},
	section: {
		paddingTop: 30,
		paddingLeft: 100,
		paddingRight: 100,
		[theme.breakpoints.down("xs")]: {
			paddingLeft: 20,
			paddingRight: 20,
		},
		"& .MuiTypography-h2": {
			paddingTop: 20,
			fontSize: "300%",
			textAlign: "center",
		},
		"& .MuiTypography-h3": {
			fontSize: "170%",
			textAlign: "center",
		},
		"& .MuiTypography-h5": {
			marginTop: 0,
			textAlign: "center",
		},
	},
	title: {
		paddingTop: 30,
		fontSize: "300%",
		textAlign: "center",
		[theme.breakpoints.down("xs")]: {
			fontSize: "250%",
		},
	},
	activeGameInfo: {
		marginLeft: "35%",
		width: "65%",
	},
	item: {
		borderRadius: 10,
		height: 225,
		backgroundColor: Colours.background,
	},
	imageContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		position: "relative",
		height: "100%",
		overflow: "hidden",
		borderTopLeftRadius: 10,
		borderTopRightRadius: 10,
	},
	image: {
		width: "100%",
		minHeight: "100%",
		objectFit: "cover",
	},
	gradient: {
		cursor: "pointer",
		position: "absolute",
		width: "100%",
		height: "100%",
		background: `linear-gradient(to bottom,  ${Colours.transparent} 0%,${Colours.darkBackground} 100%)`,
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-end",
		alignItems: "center",
	},
	textContainer: {
		position: "absolute",
		height: "35%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	},
	stars: {
		paddingTop: 10,
	},
}));

export default function Favourites(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [currentId, setCurrentId] = useState(null);
	const { favourites } = useSelector((state) => state.games);
	const { drawerStatus } = useSelector((state) => state.status);
	const smallWidth = window.innerWidth < 600;

	const gridColumns = smallWidth ? 12 : 3;

	function handleLoadDetail(e) {
		if (e === currentId) {
			dispatch(setDrawerStatus(false));
			dispatch(setVideoPlayingStatus(false));
			setCurrentId(null);
		} else {
			dispatch(setVideoPlayingStatus(true));
			dispatch(setDrawerStatus(true));
			dispatch(loadDetail(e));
			setCurrentId(e);
		}
	}

	function buildFavouriteItem(game) {
		return (
			<Grid alignContent="space-around" item xs={gridColumns} key={game.name}>
				<div className={classes.item}>
					<div className={classes.imageContainer}>
						<img
							src={game.background_image}
							alt={game.name}
							className={classes.image}
						></img>
						<div
							className={classes.gradient}
							onClick={() => handleLoadDetail(game.id)}
						>
							<div className={classes.textContainer}>
								<Typography component="h1" variant="h3">
									{game.name}
								</Typography>
								<Typography component="h1" variant="h5">
									{game.developers.length > 0
										? game.developers[0].name
										: "UNKNOWN"}
								</Typography>
							</div>
						</div>
					</div>
				</div>
			</Grid>
		);
	}

	return (
		<>
			<GameInfo />
			<div
				className={classNames(
					classes.container,
					drawerStatus ? classes.activeGameInfo : null
				)}
			>
				<div className={classes.nav}>
					<NavBar />
				</div>
				<Typography className={classes.title} component="h1" variant="h2">
					Favourites
				</Typography>
				<div className={classes.section}>
					<Grid alignContent="space-around" container spacing={6}>
						{favourites.map((el) => {
							return buildFavouriteItem(el.data);
						})}
					</Grid>
				</div>
			</div>
		</>
	);
}
