import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyCfXtkJ9RK5AghSTcC9Q768IhDVF8chD8M",
  authDomain: "retrobase-296012.firebaseapp.com",
  databaseURL: "https://retrobase-296012.firebaseio.com",
  projectId: "retrobase-296012",
  storageBucket: "retrobase-296012.appspot.com",
  messagingSenderId: "818953627671",
  appId: "1:818953627671:web:eb39e2ff5f0a5c41701452",
  measurementId: "G-D4H71VWHNE",
};

const config = firebase.initializeApp(firebaseConfig);

export default config;
