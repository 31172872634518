import { combineReducers } from "redux";
import gamesReducer from "./gamesReducer";
import detailReducer from "./detailReducer";
import authReducer from "./authReducer";
import statusReducer from "./statusReducer";

const allReducers = combineReducers({
  games: gamesReducer,
  detail: detailReducer,
  auth: authReducer,
  status: statusReducer,
});

export default allReducers;
