import React from "react";
import { Route, Redirect } from "react-router-dom";

const PublicRoute = ({
	component: Component,
	restricted,
	isSignedIn,
	isLoadingAuth,
	...rest
}) => {
	return (
		<Route
			{...rest}
			render={(props) =>
				isSignedIn && restricted && !isLoadingAuth ? (
					<Redirect to="/home" />
				) : (
					!isLoadingAuth && <Component {...props} />
				)
			}
		/>
	);
};

export default PublicRoute;
