import { SET_DRAWER_STATUS, SET_VIDEO_PLAYING_STATUS } from "./types";

export const setDrawerStatus = (drawerStatus) => (dispatch) => {
	dispatch({
		type: SET_DRAWER_STATUS,
		payload: drawerStatus,
	});
};

export const setVideoPlayingStatus = (videoPlayingStatus) => (dispatch) => {
	dispatch({
		type: SET_VIDEO_PLAYING_STATUS,
		payload: videoPlayingStatus,
	});
};
