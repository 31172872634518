import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import image from "../images/Retrobase.png";
import smallImage from "../images/RB.png";

const useStyles = makeStyles((theme) => ({
	container: {
		width: 125,
		minWidth: 100,
		marginLeft: 25,
		marginRight: 25,
		[theme.breakpoints.down("xs")]: {
			width: 30,
			minWidth: 30,
			marginLeft: 10,
			marginRight: 10,
		},
	},
}));

export default function Logo() {
	const classes = useStyles();
	let logo = image;

	if (window.innerWidth < 600) {
		logo = smallImage;
	}

	return (
		<img alt="RetroBase Logo" src={logo} className={classes.container}></img>
	);
}
