import { SIGN_IN, SIGN_OUT } from "../actions/types";

const initialState = {
	isSignedIn: false,
	userId: null,
	userName: "",
	userImage: null,
	userEmail: null,
	isLoadingAuth: true,
};

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case SIGN_IN:
			return {
				...state,
				isSignedIn: true,
				userId: action.payload.id,
				userName: action.payload.name,
				userImage: action.payload.image,
				userEmail: action.payload.email,
				isLoadingAuth: false,
			};
		case SIGN_OUT:
			return {
				...state,
				isSignedIn: false,
				userId: null,
				userName: "",
				userImage: null,
				userEmail: null,
				isLoadingAuth: false,
			};
		default:
			return { ...state };
	}
};

export default authReducer;
