import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

const useStyles = makeStyles((theme) => ({
	image: {
		width: "100%",
		borderRadius: 10,
		marginBottom: 20,
	},
}));

export default function GalleryDialog({ images, open, setOpen }) {
	const classes = useStyles();

	const handleClose = () => {
		setOpen(false);
	};

	const descriptionElementRef = React.useRef(null);
	React.useEffect(() => {
		if (open) {
			const { current: descriptionElement } = descriptionElementRef;
			if (descriptionElement !== null) {
				descriptionElement.focus();
			}
		}
	}, [open]);

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				scroll="body"
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description"
				maxWidth="lg"
			>
				<DialogContent dividers={true} style={{ borderRadius: 20 }}>
					<DialogContentText
						id="scroll-dialog-description"
						ref={descriptionElementRef}
						tabIndex={-1}
					>
						{images.map((img) => {
							return (
								<img src={img.image} alt={img.id} className={classes.image} />
							);
						})}
					</DialogContentText>
				</DialogContent>
			</Dialog>
		</div>
	);
}
