import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";

import { Colours } from "../helpers/Colours";
import { smallImage } from "../helpers/Utils";

import GalleryDialog from "./GalleryDialog";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "space-around",
		overflow: "hidden",
		backgroundColor: Colours.darkBackground,
	},
	gridList: {
		width: "100%",
	},
	icon: {
		color: "rgba(255, 255, 255, 0.54)",
	},
}));

export default function GalleryList({ images }) {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);

	const handleDialogOpen = () => {
		setOpen(true);
	};

	return (
		<div className={classes.root}>
			<GridList cellHeight={120} className={classes.gridList}>
				<GalleryDialog images={images} setOpen={setOpen} open={open} />
				{images.map((tile) => (
					<GridListTile key={tile.id}>
						<img
							onClick={handleDialogOpen}
							src={smallImage(tile.image, 420)}
							alt={tile.id}
						/>
					</GridListTile>
				))}
			</GridList>
		</div>
	);
}
