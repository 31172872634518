import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Colours } from "../helpers/Colours";

// State / Reducers
import { loadDetail } from "../actions/detailAction";
import {
	setDrawerStatus,
	setVideoPlayingStatus,
} from "../actions/statusAction";
import config from "../config";

import { loadFavourites } from "../actions/gamesAction";

// Material
import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import IconButton from "@material-ui/core/IconButton";
import { Typography } from "@material-ui/core";

// Components
import StarItem from "./StarItem";

const ITEM_HEIGHT = window.innerWidth < 600 ? 120 : 180;

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
		flexDirection: "column",
		borderBlockWidth: 50,
		marginBottom: 20,

		"& .MuiTypography-h5": {
			zIndex: 998,
			color: Colours.primary,
			fontWeight: 700,
			marginBottom: 20,
			textAlign: "left",
			fontSize: "180%",
			[theme.breakpoints.down("xs")]: {
				fontSize: "110%",
				fontWeight: "bold",
			},
		},
	},
	gridList: {
		flexWrap: "nowrap",
		transform: "translateZ(0)",
	},
	title: {
		color: theme.palette.primary.light,
	},
	titleBar: {
		background:
			"linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
	},
	item: {
		transition: "all 0.2s ease",
		maxHeight: ITEM_HEIGHT,
		// maxHeight: 999,
		borderRadius: 25,
		"& img": {
			width: "100%",
			objectFit: "cover",
			minHeight: "100%",
			cursor: "pointer",
			borderRadius: 10,
		},
		"&:hover": {
			transform: `scale(1.05,1.05)`,
			zIndex: 999,
		},
	},
}));

export default function GameGridList({ games, listTitle }) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const database = config.database();
	const [columns, setColumns] = useState(6.2);
	const [currentId, setCurrentId] = useState(null);
	const { drawerStatus } = useSelector((state) => state.status);
	const { userId } = useSelector((state) => state.auth);

	const { favouriteIds } = useSelector((state) => state.games);

	useEffect(() => {
		if (window.innerWidth < 600) {
			setColumns(2.1);
		} else if (window.innerWidth < 800) {
			setColumns(3.3);
		} else if (window.innerWidth < 1100) {
			setColumns(4.8);
		} else if (window.innerWidth < 1700) {
			setColumns(6.2);
		} else if (window.innerWidth < 2500) {
			setColumns(7.2);
		} else {
			setColumns(9);
		}
	}, []);

	// Events
	function handleLoadDetail(e) {
		if (e === currentId) {
			dispatch(setDrawerStatus(false));
			dispatch(setVideoPlayingStatus(false));
			setCurrentId(null);
		} else {
			dispatch(setVideoPlayingStatus(true));
			dispatch(setDrawerStatus(true));
			dispatch(loadDetail(e));
			setCurrentId(e);
		}
	}

	async function handleFavouriteGame(id) {
		if (favouriteIds.includes(id)) {
			let userGames = await database.ref(`users/${userId}`);
			let keyID;
			userGames.on("value", (snapshot) => {
				const data = snapshot.val();
				for (const [key, value] of Object.entries(data)) {
					if (id === value.gameId) {
						keyID = key;
					}
				}
			});
			await database.ref(`users/${userId}/${keyID}`).remove();
		} else {
			await database.ref(`users/${userId}`).push().set({ gameId: id });
		}
		dispatch(loadFavourites(userId));
		setCurrentId(currentId);
	}

	// Build
	function buildGameTile(game) {
		return (
			<GridListTile key={game.id} className={classes.item}>
				<img
					onClick={() => handleLoadDetail(game.id)}
					className={classes.itemImage}
					src={game.platforms && game.short_screenshots[0].image}
					alt={game.name}
				/>
				<GridListTileBar
					title={game.name}
					classes={{
						root: classes.titleBar,
						title: classes.title,
					}}
					actionIcon={
						<IconButton
							onClick={() => handleFavouriteGame(game.id)}
							aria-label={`star ${game.name}`}
						>
							<StarItem favourited={favouriteIds.includes(game.id)} />
						</IconButton>
					}
				/>
			</GridListTile>
		);
	}

	return (
		<div className={classes.root}>
			<Typography component="h1" variant="h5">
				{listTitle}
			</Typography>
			<GridList
				className={classes.gridList}
				cols={drawerStatus ? columns - 2 : columns}
				spacing={5}
			>
				{games.map((game) => buildGameTile(game))}
			</GridList>
		</div>
	);
}
