import {
  FETCH_GAMES,
  FETCH_SEARCHED,
  CLEAR_SEARCHED,
  FETCH_FAVOURITES,
} from "../actions/types";

const initState = {
  popular: [],
  newGames: [],
  upcoming: [],
  favourites: [],
  favouriteIds: [],
  searched: [],
};

const gamesReducer = (state = initState, action) => {
  switch (action.type) {
    case FETCH_GAMES:
      return {
        ...state,
        popular: action.payload.popular,
        upcoming: action.payload.upcoming,
        newGames: action.payload.newGames,
      };
    case FETCH_FAVOURITES:
      return {
        ...state,
        favourites: action.payload.games,
        favouriteIds: action.payload.ids,
      };
    case FETCH_SEARCHED:
      return {
        ...state,
        searched: action.payload,
      };
    case CLEAR_SEARCHED:
      return {
        ...state,
        searched: [],
      };
    default:
      return { ...state };
  }
};

export default gamesReducer;
