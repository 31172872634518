import { SET_DRAWER_STATUS, SET_VIDEO_PLAYING_STATUS } from "../actions/types";

const initialState = {
  drawerStatus: false,
  videoPlayingStatus: false,
};

const functionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DRAWER_STATUS:
      return {
        ...state,
        drawerStatus: action.payload,
      };
    case SET_VIDEO_PLAYING_STATUS:
      return {
        ...state,
        videoPlayingStatus: action.payload,
      };
    default:
      return { ...state };
  }
};

export default functionReducer;
