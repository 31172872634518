// Utils
import React from "react";
import { Colours } from "../helpers/Colours";
import { makeStyles } from "@material-ui/core";
import classNames from "classnames";

// State
import { useSelector, useDispatch } from "react-redux";
import {
	setDrawerStatus,
	setVideoPlayingStatus,
} from "../actions/statusAction";
// Material
import Fab from "@material-ui/core/Fab";
import ClearIcon from "@material-ui/icons/Clear";

// Components
import ReactPlayer from "react-player";
import DrawerTabs from "./DrawerTabs";

const useStyles = makeStyles((theme) => ({
	container: {
		position: "fixed",
		top: 0,
		left: 0,
		width: "35%",
		height: "100vh",
		backgroundColor: Colours.darkBackground,
		overflowY: "scroll",
		transform: "translate(-100%)",
		transition: "all 0.4s ease",
		Opacity: 0,
		zIndex: 999,
		[theme.breakpoints.down("xs")]: {
			width: `100%`,
		},
	},
	activeGameInfo: {
		transform: "translate(0%)",
		Opacity: 1,
	},
	topSection: {
		transition: "all 0.3s ease",
		width: "100%",
		// height: "40vh",
	},
	coverMedia: {
		width: "100%",
		// height: "40vh",
		paddingTop: 0,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		alignSelf: "center ",
	},
	crossIcon: {
		position: "absolute",
		right: 10,
		top: 10,
		opacity: 0.3,
	},
}));

export default function GameInfo() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { drawerStatus, videoPlayingStatus } = useSelector(
		(state) => state.status
	);
	const { game, isLoading } = useSelector((state) => state.detail);

	function handleVideoEnd() {
		dispatch(setVideoPlayingStatus(false));
	}

	function handleDrawerClose() {
		dispatch(setDrawerStatus(false));
		dispatch(setVideoPlayingStatus(false));
	}

	function buildVideoPlayer() {
		return (
			!isLoading && (
				<div className={classes.coverMedia}>
					<ReactPlayer
						url={game.clip ? game.clip.clip : ""}
						playing={videoPlayingStatus}
						maxWidth="100%"
						controls={true}
						onEnded={handleVideoEnd}
					/>
				</div>
			)
		);
	}

	function buildBackgroundCover() {
		return (
			<div>
				<img
					className={classes.coverMedia}
					src={game.background_image ? game.background_image : ""}
					alt={game.name}
				></img>
			</div>
		);
	}

	return (
		<div
			className={classNames(
				classes.container,
				drawerStatus ? classes.activeGameInfo : null
			)}
		>
			<div className={classes.topSection}>
				{game.clip !== null && videoPlayingStatus
					? buildVideoPlayer()
					: buildBackgroundCover()}
				<Fab
					onClick={handleDrawerClose}
					color="secondary"
					className={classes.crossIcon}
				>
					<ClearIcon />
				</Fab>
			</div>
			<div className={classes.bottomSection}>
				<DrawerTabs />
			</div>
		</div>
	);
}
