// const BASE_STEAM_URL = "http://api.steampowered.com/";

//========== Steam Key ==========

//Request Format
//http://api.steampowered.com/<interface name>/<method name>/v<version>/?key=<api key>&format=<format>

//Example Request
// http://api.steampowered.com/ISteamNews/GetNewsForApp/v0002/?appid=440&count=3&maxlength=300&format=json

//Key: 4BA0A3E996B01442DDA4584BAADC8712
//Domain Name: 86.10.162.84

// const steamUrl = `http://api.steampowered.com/`;
// const steamAPIKey = `4BA0A3E996B01442DDA4584BAADC8712`;
// const interfaceName = `ISteamNews/`;
// const methodName = `GetNewsForApp/`;
// const version = `0002/`;
// const appid = `440`;
// const steamData = `http://api.steampowered.com/<interface name>/<method name>/v<version>/?key=${steamAPIKey}`;

// async function returnSteamData() {
//     const data = await Axios.get(
//         `${steamUrl}${interfaceName}${methodName}v${version}?appid=${appid}&key=${steamAPIKey}&count=3&maxLength=300&format=json`
//     );

//     // const data = await Axios.get(
//     //     `http://api.steampowered.com/ISteamNews/GetNewsForApp/v0002/?appid=440&count=3&maxlength=300&format=json`
//     // );

//     console.log(data);
// }

export const devAPIKey = "?key=cc75d054d4a54e978a932ad07314ef83";
export const retrobaseAPIKey = "?key=f2a639a4c03c4a2982cd3420f58611b4";

const BASE_URL = "https://api.rawg.io/api/";
const NUM_RESULTS = 20;

const popularGamesURL = (genre) => `${BASE_URL}${ReturnGame("popular", genre)}`;
const upcomingGamesURL = (genre) =>
	`${BASE_URL}${ReturnGame("upcoming", genre)}`;
const newGamesURL = (genre) => `${BASE_URL}${ReturnGame("new", genre)}`;
//GAME DETAILS
const gameDetailsURL = (game_id) =>
	`${BASE_URL}games/${game_id}${retrobaseAPIKey}`;
//Game ScreenShots
const gameScreenshotURL = (game_id) =>
	`${BASE_URL}games/${game_id}/screenshots${retrobaseAPIKey}`;
//Searched game
const searchGameURL = (game_name) =>
	`${BASE_URL}games${retrobaseAPIKey}&search=${game_name}&ordering=-rating&page_size=${NUM_RESULTS}`;

const GlobalController = {
	popularGamesURL,
	upcomingGamesURL,
	newGamesURL,
	gameDetailsURL,
	gameScreenshotURL,
	searchGameURL,
};

function getCurrentMonth() {
	const month = new Date().getMonth();
	return month < 10 ? `0${month}` : month;
}

function getCurrentDay() {
	const day = new Date().getDate();
	return day < 10 ? `0${day}` : day;
}

getCurrentMonth();
//Popular Games
const currentYear = new Date().getFullYear();
const currentMonth = getCurrentMonth();
const currentDay = getCurrentDay();
const currentDate = `${currentYear}-${currentMonth}-${currentDay}`;
const lastYear = `${currentYear - 1}-${currentMonth}-${currentDay}`;
const nextYear = `${currentYear + 1}-${currentMonth}-${currentDay}`;

//Popular Games
function ReturnGame(type, genre) {
	switch (type) {
		case "popular":
			if (genre === "all") {
				return `games${retrobaseAPIKey}&dates=${lastYear},${currentDate}&ordering=-rating&page_size=${NUM_RESULTS}`;
			} else {
				return `games${retrobaseAPIKey}&dates=${lastYear},${currentDate}&genres=${
					genre !== null && genre
				}&ordering=-rating&page_size=${NUM_RESULTS}`;
			}
		case "upcoming":
			if (genre === "all") {
				return `games${retrobaseAPIKey}&dates=${currentDate},${nextYear}&ordering=-added&page_size=${NUM_RESULTS}`;
			} else {
				return `games${retrobaseAPIKey}&dates=${currentDate},${nextYear}&genres=${
					genre !== null && genre
				}&ordering=-added&page_size=${NUM_RESULTS}`;
			}
		case "new":
			if (genre === "all") {
				return `games${retrobaseAPIKey}&dates=${lastYear},${currentDate}&ordering=-released&page_size=${NUM_RESULTS}`;
			} else {
				return `games${retrobaseAPIKey}&dates=${lastYear},${currentDate}&genres=${
					genre !== null && genre
				}&ordering=-released&page_size=${NUM_RESULTS}`;
			}
		default:
			break;
	}
}
export default GlobalController;
