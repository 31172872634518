import React from "react";
import { makeStyles } from "@material-ui/core";
import "../styles/app.scss";
import { Switch } from "react-router-dom";
import { useSelector } from "react-redux";

// Pages
import Dashboard from "../domain/Dashboard";
import Home from "../domain/Home";
import Favourites from "../domain/Favourites";

// Components
import PrivateRoute from "../components/PrivateRoute";
import PublicRoute from "../components/PublicRoute";
import GoogleAuth from "../components/GoogleAuth";

const useStyles = makeStyles((theme) => ({}));

function App() {
	const classes = useStyles();
	const { isSignedIn, isLoadingAuth } = useSelector((state) => state.auth);

	return (
		<div className={classes.body}>
			<Switch>
				<PublicRoute
					path="/"
					component={Dashboard}
					exact
					restricted={true}
					isSignedIn={isSignedIn}
					isLoadingAuth={isLoadingAuth}
				/>
				<PrivateRoute
					path="/home"
					component={Home}
					isSignedIn={isSignedIn}
					isLoadingAuth={isLoadingAuth}
				/>
				<PrivateRoute
					path="/favourites"
					component={Favourites}
					isSignedIn={isSignedIn}
					isLoadingAuth={isLoadingAuth}
				/>
			</Switch>
			<GoogleAuth loginPage={false} />
		</div>
	);
}

export default App;
