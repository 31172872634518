import { GET_DETAIL, CHANGE_GENRE, LOADING_DETAIL } from "../actions/types";

const initialState = {
  game: { platforms: [] },
  screen: { results: [] },
  clip: "",
  genre: null,
  isLoading: true,
};

const detailReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DETAIL:
      return {
        ...state,
        game: action.payload.game,
        screen: action.payload.screen,
        clip: action.payload.clip,
        isLoading: false,
      };
    case CHANGE_GENRE:
      return {
        ...state,
        isLoading: true,
      };

    case LOADING_DETAIL:
      return {
        ...state,
        isLoading: true,
      };
    default:
      return { ...state };
  }
};

export default detailReducer;
