import React, { useEffect, useState } from "react";
import { Colours } from "../helpers/Colours";

import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";

function StarItem({ favourited }) {
	const [isFavourited, setIsFavourited] = useState(false);

	useEffect(() => {
		if (favourited) {
			setIsFavourited(true);
		} else {
			setIsFavourited(false);
		}
	}, [favourited]);

	return (
		<div>
			{isFavourited ? (
				<StarIcon style={{ color: "yellow" }} />
			) : (
				<StarBorderIcon style={{ color: Colours.white }} />
			)}
		</div>
	);
}

export default StarItem;
