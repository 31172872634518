import React from "react";
import google from "../images/google.png";

// State and Reducers
import { connect } from "react-redux";
import { signIn, signOut } from "../actions/authAction";

// Material
import Button from "@material-ui/core/Button";
import { Colours } from "../helpers/Colours";

class GoogleAuth extends React.Component {
	componentDidMount() {
		window.gapi.load("client:auth2", () => {
			window.gapi.client
				.init({
					clientId:
						"818953627671-7h2rtsc5oua0688cpmh9sm8atuqn105t.apps.googleusercontent.com",
					scope: "email",
				})
				.then(() => {
					this.auth = window.gapi.auth2.getAuthInstance();
					this.onAuthChange(this.auth.isSignedIn.get());
					this.auth.isSignedIn.listen(this.onAuthChange);
				});
		});
	}

	onAuthChange = (isSignedIn) => {
		var profile = this.auth.currentUser.get().getBasicProfile();
		isSignedIn
			? this.props.signIn(
					this.auth.currentUser.get().getId(),
					profile.getName(),
					profile.getImageUrl(),
					profile.getEmail()
			  )
			: this.props.signOut();
	};

	onSignInClick = async () => {
		await this.auth.signIn();
		localStorage.removeItem("signedIn");
		localStorage.setItem("signedIn", true);
		// console.log(this.props);
	};

	onSignOutClick = () => {
		this.auth.signOut();
	};

	renderSignInButton() {
		return (
			<div style={{ display: "flex" }}>
				<Button
					fullWidth
					onClick={this.onSignInClick}
					style={{
						backgroundColor: Colours.white,
						marginBottom: 15,
						marginTop: 20,
						padding: "10px 20px",
					}}
				>
					<img
						alt="Google icon"
						src={google}
						style={{ width: 22, height: 22, marginRight: 10 }}
					></img>
					Continue with Google
				</Button>
			</div>
		);
	}

	renderSignOutButton() {
		return (
			<div style={{ display: "flex" }}>
				<Button
					fullWidth
					onClick={this.onSignOutClick}
					style={{ backgroundColor: Colours.blue, marginBottom: 15 }}
				>
					Sign Out
				</Button>
			</div>
		);
	}

	render() {
		return (
			<div>
				{this.props.loginPage && this.renderSignInButton()}
				{/* {this.renderSignOutButton()} */}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return { isSignedIn: state.auth.isSignedIn };
};

export default connect(mapStateToProps, { signIn, signOut })(GoogleAuth);
