import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { Colours } from "../helpers/Colours";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarHalfIcon from "@material-ui/icons/StarHalf";

const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		"& .MuiTypography-h4": {
			color: Colours.bodyText,
			fontSize: "130%",
		},
		[theme.breakpoints.down("xs")]: {
			flexDirection: "column",
			alignItems: "flex-start",
		},
	},
	stars: {
		paddingRight: 20,
		display: "flex",
		alignItems: "center",
	},
	textContainer: {
		display: "flex",
		alignItems: "center",
		[theme.breakpoints.down("xs")]: {
			marginTop: 10,
		},
	},
}));

export default function Stars({ rating, numRatings }) {
	const classes = useStyles();

	function buildStarUnit(index) {
		let num = rating;

		if (index >= 1) {
			num -= index * 18;
		}
		if (num < 0) {
			num = 0;
		}

		if (num < 9) {
			return <StarBorderIcon htmlColor="yellow" />;
		} else if (num < 17) {
			return <StarHalfIcon htmlColor="yellow" />;
		} else {
			return <StarIcon htmlColor="yellow" />;
		}
	}

	function buildStars() {
		return (
			<>
				{buildStarUnit(0)}
				{buildStarUnit(1)}
				{buildStarUnit(2)}
				{buildStarUnit(3)}
				{buildStarUnit(4)}
			</>
		);
	}

	function buildStarContainer() {
		return (
			<>
				<div className={classes.stars}>{buildStars()}</div>
				<div className={classes.textContainer}>
					<Typography component="h1" variant="h4">
						{numRatings} Ratings ({rating}% Liked)
					</Typography>
				</div>
			</>
		);
	}

	function buildNullRequirementContainer() {
		return (
			<Typography style={{ color: "skyblue" }} component="h1" variant="h4">
				Not Enough Ratings Recieved
			</Typography>
		);
	}

	// game.metacritic !== null || game.ratings > 50;

	return (
		<div className={classes.container}>
			{rating !== null && numRatings > 50
				? buildStarContainer()
				: buildNullRequirementContainer()}
		</div>
	);
}
