import React, { useEffect } from "react";
import classNames from "classnames";

// State / Database
import { useSelector, useDispatch } from "react-redux";
import { loadGames, loadFavourites } from "../actions/gamesAction";

// Style
import { Colours } from "../helpers/Colours";

//Material Components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

// Components
import NavBar from "../components/NavBar";
import GameGridList from "../components/GameGridList";
import GameInfo from "../components/GameInfo";
import CarouselComp from "../components/CarouselComp";

const useStyles = makeStyles((theme) => ({
	container: {
		transition: "all 0.5s ease",
	},
	activeGameInfo: {
		marginLeft: "35%",
	},
	topContainer: {
		height: "75vh",
		[theme.breakpoints.down("xs")]: {
			display: "none",
		},
	},
	innerTopContainer: {
		height: "100%",
		width: "100vw",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		position: "relative",
		"& .MuiContainer-maxWidthXs": {
			maxWidth: "100%",
		},
	},
	imageOverlay: {
		height: "101%",
		width: "100%",
		position: "absolute",
		background: `linear-gradient(to bottom,  ${Colours.transparent} 0%,${Colours.background} 100%)`,
	},
	nav: {
		position: "fixed",
		top: 0,
		zIndex: 999,

		[theme.breakpoints.down("xs")]: {
			position: "relative",
		},
	},
	bottomContainer: {
		backgroundColor: Colours.background,
		marginBottom: 300,
	},
	bottomSectionContainer: {
		paddingLeft: 50,
		paddingRight: 50,
		[theme.breakpoints.down("xs")]: {
			paddingLeft: 20,
			paddingRight: 20,
			height: "20vh",
		},
	},
	mastheadContainer: {
		marginLeft: 50,
		marginBottom: 75,
		display: "flex",
		flexDirection: "column",
		alignSelf: "flex-end",
		"& .MuiContainer-root": {
			padding: 0,
		},
	},
	InformationSection: {
		flexDirection: "column",

		"& .MuiTypography-h2": {
			fontSize: "450%",
		},
		"& .MuiTypography-h5": {
			color: Colours.primary,
			fontSize: "150%",
			margin: 0,
		},
	},
	dropdown: {
		minWidth: 200,
		marginTop: 40,
		borderColor: Colours.primary,
		backgroundColor: Colours.darkBackground,
		borderRadius: 10,
		[theme.breakpoints.down("xs")]: {
			minWidth: "100%",
		},
		"& .MuiSelect-icon": {
			color: Colours.white,
		},
	},
	dropdownItem: {
		backgroundColor: Colours.darkBackground,
		"&:hover": {
			color: Colours.primary,
			backgroundColor: Colours.grey,
		},
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	descriptionBox: {
		marginTop: 10,
		padding: 25,
	},
	steamContainer: {
		width: 210,
		height: 50,
		backgroundColor: "rgba(50, 168, 82, 0.20)",
		marginBottom: 10,
		justifyContent: "center",
		display: "flex",
		alignItems: "center",
		"& .MuiTypography-h6": {
			color: Colours.green,
		},
	},
}));

export default function Home(props) {
	const classes = useStyles();
	const dispatch = useDispatch();

	const { popular, newGames, upcoming, searched } = useSelector(
		(state) => state.games
	);

	const { userId } = useSelector((state) => state.auth);
	const [genre, setGenre] = React.useState("all");
	const { drawerStatus } = useSelector((state) => state.status);

	useEffect(() => {
		dispatch(loadGames(genre));
		dispatch(loadFavourites(userId));
	}, []);

	const handleChange = (event) => {
		setGenre(event.target.value);
		dispatch(loadGames(event.target.value));
	};

	function buildGenreDropdownMenu() {
		return (
			<div>
				<FormControl variant="outlined" className={classes.dropdown}>
					<InputLabel id="demo-simple-select-outlined-label">
						Select Genre
					</InputLabel>
					<Select
						labelId="demo-simple-select-outlined-label"
						id="demo-simple-select-outlined"
						color="primary"
						style={{ color: Colours.primary }}
						value={genre}
						onChange={handleChange}
						label="Age"
					>
						<MenuItem className={classes.dropdownItem} value={"all"}>
							All
						</MenuItem>
						<MenuItem className={classes.dropdownItem} value={"action"}>
							Action
						</MenuItem>
						<MenuItem className={classes.dropdownItem} value={"adventure"}>
							Adventure
						</MenuItem>
						<MenuItem className={classes.dropdownItem} value={"puzzle"}>
							Puzzle
						</MenuItem>
						<MenuItem
							className={classes.dropdownItem}
							value={"massively-multiplayer"}
						>
							Massively Multiplayer
						</MenuItem>
						<MenuItem className={classes.dropdownItem} value={"platformer"}>
							Platformer
						</MenuItem>
						<MenuItem className={classes.dropdownItem} value={"strategy"}>
							Strategy
						</MenuItem>
						<MenuItem className={classes.dropdownItem} value={"shooter"}>
							Shooter
						</MenuItem>
						<MenuItem className={classes.dropdownItem} value={"indie"}>
							Indie
						</MenuItem>
						<MenuItem className={classes.dropdownItem} value={"simulation"}>
							Simulation
						</MenuItem>
						<MenuItem className={classes.dropdownItem} value={"arcade"}>
							Arcade
						</MenuItem>
						<MenuItem className={classes.dropdownItem} value={"racing"}>
							Racing
						</MenuItem>
						<MenuItem className={classes.dropdownItem} value={"fighting"}>
							Fighting
						</MenuItem>
						<MenuItem className={classes.dropdownItem} value={"educational"}>
							Educational
						</MenuItem>
						<MenuItem className={classes.dropdownItem} value={"card"}>
							Card
						</MenuItem>
						<MenuItem className={classes.dropdownItem} value={"sports"}>
							Sports
						</MenuItem>
					</Select>
				</FormControl>
			</div>
		);
	}

	function buildBottomSection() {
		return (
			<div className={classes.bottomSectionContainer}>
				{searched.length !== 0 && (
					<GameGridList
						games={searched.map((game) => game)}
						listTitle="Searched Games"
					/>
				)}
				{buildGenreDropdownMenu()}
				{popular.length !== 0 && (
					<GameGridList
						games={popular.map((game) => game)}
						listTitle="Most Popular Games"
					/>
				)}

				{newGames.length !== 0 && (
					<GameGridList
						games={newGames.map((game) => game)}
						listTitle="New Games"
					/>
				)}
				{upcoming.length !== 0 && (
					<GameGridList
						games={upcoming.map((game) => game)}
						listTitle="Upcoming Games"
					/>
				)}
			</div>
		);
	}

	return (
		<>
			<div
				className={classNames(
					classes.container,
					drawerStatus ? classes.activeGameInfo : null
				)}
			>
				<GameInfo />
				<div className={classes.nav}>
					<NavBar />
				</div>
				<div className={classes.topContainer}>
					<div className={classes.innerTopContainer}>
						<CarouselComp games={popular} />
						<div className={classes.imageOverlay}></div>
					</div>
				</div>
				<div className={classes.bottomContainer}>{buildBottomSection()}</div>
			</div>
		</>
	);
}
