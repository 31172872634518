import React, { useState } from "react";
import classNames from "classnames";
import Logo from "./Logo";
import { Colours } from "../helpers/Colours";
import { Link } from "react-router-dom";

// State
import { searchGames } from "../actions/gamesAction";
import { useSelector, useDispatch } from "react-redux";
import { signOut } from "../actions/authAction";

// Material
import { fade, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import SearchIcon from "@material-ui/icons/Search";
import MoreIcon from "@material-ui/icons/MoreVert";

const useStyles = makeStyles((theme) => ({
	grow: {
		transition: "all 0.3s ease",
		flexGrow: 1,
		width: "100vw",
		overflowX: "hidden",
		height: "7vh",
		borderBottom: `1px solid ${Colours.whiteBorder}`,
		[theme.breakpoints.down("xs")]: {
			height: "11vh",
		},
		"& .MuiAppBar-colorPrimary": {
			backgroundColor: Colours.background,
		},
		"& .MuiSvgIcon-root": {
			color: Colours.primary,
		},
	},
	activeGameInfo: {
		width: "65vw",
		[theme.breakpoints.down("xs")]: {
			width: 0,
			display: "none",
		},
	},
	title: {
		display: "none",
		[theme.breakpoints.up("sm")]: {
			display: "block",
		},
	},
	search: {
		position: "relative",
		display: "flex",
		alignItems: "center",
		alignSelf: "center",
		maxHeight: 45,
		borderRadius: theme.shape.borderRadius,
		marginTop: 10,
		marginBottom: 10,
		backgroundColor: fade(theme.palette.common.white, 0.1),
		minWidth: 170,
		"&:hover": {
			backgroundColor: fade(theme.palette.common.white, 0.15),
		},
		// marginRight: theme.spacing(3),
		marginLeft: 0,
		width: "100%",
		[theme.breakpoints.up("sm")]: {
			marginLeft: theme.spacing(3),
			marginRight: theme.spacing(3),

			width: "auto",
		},
	},
	searchIcon: {
		padding: theme.spacing(0, 2),
		position: "absolute",
		pointerEvents: "none",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	inputRoot: {
		color: "white",
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create("width"),
		width: "100%",
		display: "flex",
		alignSelf: "center",
		[theme.breakpoints.up("md")]: {
			width: "20ch",
		},
	},
	navbarTextContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		paddingRight: 15,
		"& .MuiTypography-h6": {
			fontSize: 14,
			borderRadius: 5,
			padding: 20,
			paddingTop: 5,
			paddingBottom: 5,
			marginRight: 10,
			"&:hover": {
				borderBottomWidth: 2,
				borderColor: "white",
				backgroundColor: fade(theme.palette.common.white, 0.1),
				color: "white",
			},
		},
	},
	navbarText: {
		paddingRight: 15,
		paddingLeft: 15,
		fontSize: 14,
		letterSpacing: 1,
		color: Colours.bodyText,
		textAlign: "center",
	},
	sectionDesktop: {
		display: "none",
		paddingRight: 50,
		[theme.breakpoints.up("md")]: {
			display: "flex",
		},
	},
	sectionMobile: {
		display: "flex",
		[theme.breakpoints.up("md")]: {
			display: "none",
		},
	},
}));

export default function NavBar() {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [textInput, setTextInput] = useState("");
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
	const { userName, userImage } = useSelector((state) => state.auth);
	const { drawerStatus } = useSelector((state) => state.status);

	const isMenuOpen = Boolean(anchorEl);
	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

	function handleSubmitSearch(e) {
		// e.preventDefault();

		if (e.target.value !== "") {
			setTextInput(e.target.value);
			dispatch(searchGames(e.target.value));
		} else if (e.target.value === "") {
			setTextInput("");
			dispatch({ type: "CLEAR_SEARCHED" });
		}
	}

	const handleProfileMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMobileMenuClose = () => {
		setMobileMoreAnchorEl(null);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
		handleMobileMenuClose();
	};

	const handleMobileMenuOpen = (event) => {
		setMobileMoreAnchorEl(event.currentTarget);
	};

	const handleSignOut = async () => {
		setAnchorEl(null);
		handleMobileMenuClose();
		localStorage.removeItem("signedIn");
		localStorage.setItem("signedIn", false);
		await window.gapi.auth2.getAuthInstance().signOut();
		dispatch(signOut);
	};

	const menuId = "primary-search-account-menu";
	const renderMenu = (
		<Menu
			anchorEl={anchorEl}
			id={menuId}
			keepMounted
			style={{ marginTop: 40, marginLeft: 12 }}
			open={isMenuOpen}
			onClose={handleMenuClose}
		>
			<MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
		</Menu>
	);

	const mobileMenuId = "primary-search-account-menu-mobile";
	const renderMobileMenu = (
		<Menu
			anchorEl={mobileMoreAnchorEl}
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
			id={mobileMenuId}
			keepMounted
			transformOrigin={{ vertical: "top", horizontal: "right" }}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}
		>
			<MenuItem>
				<Link to="/favourites">
					<div style={{ display: "flex", alignItems: "center" }}>
						<p>Favourites</p>
					</div>
				</Link>
			</MenuItem>
			<MenuItem onClick={handleSignOut}>
				<p>Sign Out</p>
			</MenuItem>
		</Menu>
	);

	function buildNavLinks() {
		return (
			<>
				<Link to="/favourites">
					<Typography
						component="h1"
						variant="h6"
						className={classes.navbarText}
						noWrap={true}
					>
						My Favourites
					</Typography>
				</Link>
			</>
		);
	}

	return (
		<div
			className={classNames(
				classes.grow,
				drawerStatus ? classes.activeGameInfo : null
			)}
		>
			<AppBar position="static">
				<Toolbar>
					<Link to="/home">
						<Logo />
					</Link>
					<div className={classes.navbarTextContainer}>
						{window.innerWidth > 600 && buildNavLinks()}
					</div>
					<div className={classes.grow} />
					<div className={classes.sectionDesktop}>
						<Typography
							component="h1"
							variant="h6"
							className={classes.navbarText}
							noWrap={true}
							style={{ display: "flex", alignSelf: "center" }}
						>
							Welcome, {userName}
						</Typography>
						<div className={classes.search}>
							<div className={classes.searchIcon}>
								<SearchIcon />
							</div>
							<InputBase
								placeholder="Search Game Title..."
								onChange={handleSubmitSearch}
								value={textInput}
								autoFocus={true}
								classes={{
									root: classes.inputRoot,
									input: classes.inputInput,
								}}
								inputProps={{ "aria-label": "search" }}
							/>
						</div>

						<IconButton
							edge="end"
							aria-label="account of current user"
							aria-controls={menuId}
							aria-haspopup="true"
							onClick={handleProfileMenuOpen}
							color="primary"
						>
							<img
								style={{ height: "100%", maxHeight: 40, borderRadius: 25 }}
								src={userImage}
								alt={userName}
							></img>
						</IconButton>
					</div>
					<div className={classes.sectionMobile}>
						<div className={classes.search}>
							<div className={classes.searchIcon}>
								<SearchIcon />
							</div>
							<InputBase
								placeholder="Search Game Title..."
								onChange={handleSubmitSearch}
								value={textInput}
								autoFocus={true}
								classes={{
									root: classes.inputRoot,
									input: classes.inputInput,
								}}
								inputProps={{ "aria-label": "search" }}
							/>
						</div>
						<IconButton
							aria-label="show more"
							aria-controls={mobileMenuId}
							aria-haspopup="true"
							onClick={handleMobileMenuOpen}
							color="primary"
						>
							<MoreIcon />
						</IconButton>
					</div>
				</Toolbar>
			</AppBar>
			{renderMobileMenu}
			{renderMenu}
		</div>
	);
}
